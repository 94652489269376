@import '../../../assets/styles/helper.scss';

.image-picker {
  height: 48px;
  display: flex;
  align-items: center;

  &>.thumbnail {
    flex: 0 0 auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;
    object-fit: contain;
    overflow: hidden;
  }

  &>span {
    flex: auto;
    margin: 0 16px;
    @include defaultFont(normal, normal, 14px);
    @include textShrink();
  }

  .btn-container {
    display: flex;
    align-items: center;
    color: #8B8B8B;
    height: 100%;
    padding: 8px;
    font-size: 14px;
    justify-content: flex-end;
    flex: 1;
    box-shadow: 0px 0px 16px 0px inset #00000029;
    .name {
      height: 100%;
      display: flex;
      align-items: center;
    }

  }

  & .btn-clear {
    padding: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #8B8B8B;
    font-size: 24px;
    transition: 200ms linear filter;

    &:hover {
      filter: brightness(1.2);
    }
  }

  & .btn-select {
    padding: 0 16px;
    height: 100%;
    box-shadow: 0px 0px 8px 0px inset #22222280;
    background: #bebbc912;
    border-radius: 2px;
    @include defaultFont(normal, normal, 14px);
    color: #8B8B8B;
  }
}

.image-picker:not(.active) {
  &>span {
      color: #8B8B8B;
  }

  &>.thumbnail {
      display: none;
  }

  &>.btn-clear {
      display: none;
  }
}

.image-picker.active {
  &>span {
    color: $textColor;
  }
}