
.hd-row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  --col-gap-size: 0px;

  &.gutter8 {
    margin-right: -8px;
    margin-left: -8px;
  }

  &.gap8 {
    --col-gap-size: 8px !important;
  }

  &.gap32 {
    --col-gap-size: 32px !important;
  }
}

.col-center {
  display: flex;
  justify-content: center;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  flex: 0 0 auto;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: calc(8.33333333% - var(--col-gap-size));
  max-width: calc(8.33333333% - var(--col-gap-size));
}

.col-xs-2 {
  flex-basis: calc(16.66666667% - var(--col-gap-size));
  max-width: calc(16.66666667% - var(--col-gap-size));
}

.col-xs-3 {
  flex-basis: calc(25% - var(--col-gap-size));
  max-width: calc(25% - var(--col-gap-size));
}

.col-xs-4 {
  flex-basis: calc(33.33333333% - var(--col-gap-size));
  max-width: calc(33.33333333% - var(--col-gap-size));
}

.col-xs-5 {
  flex-basis: calc(41.66666667% - var(--col-gap-size));
  max-width: calc(41.66666667% - var(--col-gap-size));
}

.col-xs-6 {
  flex-basis: calc(50% - var(--col-gap-size));
  max-width: calc(50% - var(--col-gap-size));
}

.col-xs-7 {
  flex-basis: calc(58.33333333% - var(--col-gap-size));
  max-width: calc(58.33333333% - var(--col-gap-size));
}

.col-xs-8 {
  flex-basis: calc(66.66666667% - var(--col-gap-size));
  max-width: calc(66.66666667% - var(--col-gap-size));
}

.col-xs-9 {
  flex-basis: calc(75% - var(--col-gap-size));
  max-width: calc(75% - var(--col-gap-size));
}

.col-xs-10 {
  flex-basis: calc(83.33333333% - var(--col-gap-size));
  max-width: calc(83.33333333% - var(--col-gap-size));
}

.col-xs-11 {
  flex-basis: calc(91.66666667% - var(--col-gap-size));
  max-width: calc(91.66666667% - var(--col-gap-size));
}

.col-xs-12 {
  flex-basis: calc(100% - var(--col-gap-size));
  max-width: calc(100% - var(--col-gap-size));
}




@media only screen and (min-width: 900px) {
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    flex: 0 0 auto;
  }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: calc(8.33% - var(--col-gap-size));
    max-width: calc(8.33% - var(--col-gap-size));
  }

  .col-sm-2 {
    flex-basis: calc(16.66% - var(--col-gap-size));
    max-width: calc(16.66% - var(--col-gap-size));
  }

  .col-sm-3 {
    flex-basis: calc(25% - var(--col-gap-size));
    max-width: calc(25% - var(--col-gap-size));
  }

  .col-sm-4 {
    flex-basis: calc(33.33% - var(--col-gap-size));
    max-width: calc(33.33% - var(--col-gap-size));
  }

  .col-sm-5 {
    flex-basis: calc(41.66% - var(--col-gap-size));
    max-width: calc(41.66% - var(--col-gap-size));
  }

  .col-sm-6 {
    flex-basis: calc(50% - var(--col-gap-size));
    max-width: calc(50% - var(--col-gap-size));
  }

  .col-sm-7 {
    flex-basis: calc(58.33% - var(--col-gap-size));
    max-width: calc(58.33% - var(--col-gap-size));
  }

  .col-sm-8 {
    flex-basis: calc(66.66% - var(--col-gap-size));
    max-width: calc(66.66% - var(--col-gap-size));
  }

  .col-sm-9 {
    flex-basis: calc(75% - var(--col-gap-size));
    max-width: calc(75% - var(--col-gap-size));
  }

  .col-sm-10 {
    flex-basis: calc(83.33% - var(--col-gap-size));
    max-width: calc(83.33% - var(--col-gap-size));
  }

  .col-sm-11 {
    flex-basis: calc(91.66% - var(--col-gap-size));
    max-width: calc(91.66% - var(--col-gap-size));
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: calc(8.33% - var(--col-gap-size));
    max-width: calc(8.33% - var(--col-gap-size));
  }

  .col-md-2 {
    flex-basis: calc(16.66% - var(--col-gap-size));
    max-width: calc(16.66% - var(--col-gap-size));
  }

  .col-md-3 {
    flex-basis: calc(25% - var(--col-gap-size));
    max-width: calc(25% - var(--col-gap-size));
  }

  .col-md-4 {
    flex-basis: calc(33.33% - var(--col-gap-size));
    max-width: calc(33.33% - var(--col-gap-size));
  }

  .col-md-5 {
    flex-basis: calc(41.66% - var(--col-gap-size));
    max-width: calc(41.66% - var(--col-gap-size));
  }

  .col-md-6 {
    flex-basis: calc(50% - var(--col-gap-size));
    max-width: calc(50% - var(--col-gap-size));
  }

  .col-md-7 {
    flex-basis: calc(58.33% - var(--col-gap-size));
    max-width: calc(58.33% - var(--col-gap-size));
  }

  .col-md-8 {
    flex-basis: calc(66.66% - var(--col-gap-size));
    max-width: calc(66.66% - var(--col-gap-size));
  }

  .col-md-9 {
    flex-basis: calc(75% - var(--col-gap-size));
    max-width: calc(75% - var(--col-gap-size));
  }

  .col-md-10 {
    flex-basis: calc(83.33% - var(--col-gap-size));
    max-width: calc(83.33% - var(--col-gap-size));
  }

  .col-md-11 {
    flex-basis: calc(91.66% - var(--col-gap-size));
    max-width: calc(91.66% - var(--col-gap-size));
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1600px) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-gutter {
    padding-right: -8px;
    padding-left: 8px;
  }

  .col-lg-1 {
    flex-basis: calc(8.33% - var(--col-gap-size));
    max-width: calc(8.33%  - var(--col-gap-size));
  }

  .col-lg-2 {
    flex-basis: calc(16.66%  - var(--col-gap-size));
    max-width: calc(16.66%  - var(--col-gap-size));
  }

  .col-lg-3 {
    flex-basis: calc(25% - var(--col-gap-size));
    max-width: calc(25% - var(--col-gap-size));
  }

  .col-lg-4 {
    flex-basis: calc(33.33% - var(--col-gap-size));
    max-width: calc(33.33% - var(--col-gap-size));
  }

  .col-lg-5 {
    flex-basis: calc(41.66% - var(--col-gap-size));
    max-width: calc(41.66% - var(--col-gap-size));
  }

  .col-lg-6 {
    flex-basis: calc(50% - var(--col-gap-size));
    max-width: calc(50% - var(--col-gap-size));
  }

  .col-lg-7 {
    flex-basis: calc(58.33% - var(--col-gap-size));
    max-width: calc(58.33% - var(--col-gap-size));
  }

  .col-lg-8 {
    flex-basis: calc(66.66% - var(--col-gap-size));
    max-width: calc(66.66% - var(--col-gap-size));
  }

  .col-lg-9 {
    flex-basis: calc(75% - var(--col-gap-size));
    max-width: calc(75% - var(--col-gap-size));
  }

  .col-lg-10 {
    flex-basis: calc(83.33% - var(--col-gap-size));
    max-width: calc(83.33% - var(--col-gap-size));
  }

  .col-lg-11 {
    flex-basis: calc(91.66% - var(--col-gap-size));
    max-width: calc(91.66% - var(--col-gap-size));
  }

  .col-lg-12 {
    flex-basis: calc(100% - var(--col-gap-size));
    max-width: calc(100% - var(--col-gap-size));
  }
}

.screen-xs {
  .hide-xs {
    display: none;
  }
}

.screen-sm {
  .hide-sm {
    display: none;
  }
}

.screen-md {
  .hide-md {
    display: none;
  }
}

.screen-lg {
  .hide-lg {
    display: none;
  }
}


.p8 {
  padding: 8px;
  flex-direction: column;
  // flex: 1;
  display: flex;
}


.form-link {
  display: flex;
  flex: 1 1;
  color: #ed4800d4;
  padding: 4px 0px 16px 0px;
  justify-content: flex-end;
  font-size: 14px;
}

.form-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;

  .form-label {
    display: flex;
    align-items: center;
    flex: 1;
    height: 36px;
    justify-content: flex-end;
    padding-right: 16px;
    font-size: 16px;
    color: silver;
  }

  .form-input {
    width: 70%;
    display: flex;
    align-items: center;

    .hd-input {
      margin: 0px;
      width: 100%;
    }
  }
}


.form-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px;
  border: 1px solid #ed480040;
  border-radius: 8px;
  color: #ff9b9bd4;
  background: #e61e1029;
  margin: 0px 2px 8px 2px;
  line-height: 18px;

  &.small {
    flex: 0.5;
  }

  &.success {
    color: #00ed8ad4;
    background: #00ed8a14;
    border: 1px solid #00ed8a40;
  }

  &.neutral {
    color: #fff0a5d4;
    background: #fff0a514;
    border: 1px solid #fff0a540;
  }

  &.inline {
    border: none !important;
    background: none !important;
  }
}


.high-score {
  font-weight: 700;
  display: flex;
  color: #e0e0e0;
  font-size: 20px;
  justify-content: flex-end;
  align-items: center;
  div {
    padding-right: 8px;
  }
}


.hd-btn {
  // background: linear-gradient(177deg, #e8672f 0%, #ed4800 100%);
  // box-shadow: 0px 0px 4px 0px inset #ffffff44, 0px 0px 8px 0px #ed4800;
  background: linear-gradient(177deg, #326438 0%, #5c9738 100%);
  box-shadow: 0px 0px 4px 0px inset #ffffff44, 0px 0px 4px 0px #5c97386e;
  color: $textColor;
  text-shadow: 0px 0px 6px #00000044;
  font-kerning: none;
  transition: 120ms linear filter;
  &:hover {
    filter: brightness(1.2);
  }
}

.btn {
  border-radius: 12px;
  box-shadow: 0 1px 8px 0 #0b0a0a40;
  user-select: none;
  color: #33E28E;
  text-shadow: 0px 0px 4px #33E244;
  border: 1px solid #201d1d6b;
  font-size: 12px;
  background: #221F26;
  transition: 200ms linear box-shadow, 200ms linear text-shadow, 200ms linear filter;

  &:hover {
    box-shadow: 0 2px 8px 0 #0b0a0a6b;
    text-shadow: 0px 0px 8px #33E288;
    filter: brightness(1.2);
  }
}

.hd-tab {
  border-radius: 16px;
  font-size: 14px;
  white-space: nowrap;
  color: #808191c2;
  font-weight: 700;
  padding: 8px 16px;
  background: linear-gradient(270deg, #323232eb 0%, #3232329c 100%);
  box-shadow: 0px 0px 8px 0px inset #00000036;
  text-shadow: 0px 0px 3px #00000044;
  transition: 200ms linear background, 200ms linear box-shadow, 200ms linear color;
  margin-right: 8px;

  &.active, &:hover {
    text-shadow: 0px 0px 3px #00000044;
    background: linear-gradient(177deg, #e8672f 0%, #ed4800 100%);
    box-shadow: 0px 0px 4px 0px inset #ffffff44, 0px 0px 4px 0px #ed4800;
    color: #ffb993;
  }
}

.hd-btn-dialog {
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #221F26;
  height: 38px;
  border-radius: 12px;
  border: 1px solid #201d1d6b;
  padding: 8px;
  min-width: 100px;
  font-family: 'Roboto', sans-serif !important;
  font-style: normal !important;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0 2px 4px 0 #0b0a0a40;
  font-weight: 300;
  margin: 8px;
  transition: 200ms linear box-shadow, 200ms linear text-shadow, 200ms linear filter;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 8px 0 #0b0a0a6b;
    filter: brightness(1.2);
  }
}

.hd-btn-cancel {
  text-shadow: 0px 0px 8px #ff4040;
  color: #ff4040;
}

.hd-btn-accept {
  color: #33E28E;
  text-shadow: 0px 0px 4px #33e244;
  &:hover {
    text-shadow: 0px 0px 8px #33e288;
  }
  &.disabled {
    text-shadow: none;
  }
}


.disabled {
  pointer-events: none;
  &.full-select {
    pointer-events: auto;
    user-select: all;
  }
  color: $disabledText !important;
  &.always-enabled {
    pointer-events: auto;
  }
}


.hd-input-label {
  color: #bebbc9ad;
  // font-weight: bold;
  font-size: 14px;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  position: relative;
  min-height: 80px;
  transition: 200ms linear opacity;

  &.plate {
    background-color: #2B272E;
    box-shadow: 0px 4px 8px 0px #00000042, 0px 0px 16px 0px inset #0000001a;
  }
}

.hd-card {
  font-size: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background-color: #2B272E;
  border-radius: 12px;
  position: relative;
  box-shadow: 0px 0px 16px 0px inset #0000001a;
  overflow: hidden;

  &.call-to-action {
    background: linear-gradient(26deg,#9b4b2b5c 0%,#2f204269 100%),linear-gradient(170deg,#0202048c 0%,#c7491540 100%);
    width: 100%;
    height: 100%;
    transition: 200ms linear filter;
    cursor: pointer;
    line-height: 32px;

    .card-overlay {
      position: absolute;
      border-radius: 12px;
      mix-blend-mode: color-burn;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 64px 0px inset #ed480080;
      transition: 200ms linear filter;
    }

    &:hover {
      filter: brightness(1.3);

      .card-overlay {
        // filter: brightness(1.1);
      }
    }
  }
}


// note: this has to be display: block for truncate to work
.hd-label-truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: inherit;
  transition: 120ms linear color;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5ch;

  &.centered {
    justify-content: center;
  }

  &.steam {
    transition: 120ms linear color, 120ms linear text-shadow;
    // color: #a1bae3;
    // color: #aab3c2;
    color: #819dcb;
    cursor: pointer;
    &:hover {
      color: #8aa8dc;
      text-shadow: 0px 0px 6px #8aa8dc, 1px 1px 3px #000000a1;
      filter: unset;
    }
  }
}


.steam-profile-link {
  // color: #9a94e5;
  // color: #bda0b5;
  // color: #aab3c2;
  white-space: nowrap;
  // color: #a593d2;

  font-weight: 500;
  // color: #948c88;

  color: #a593d2;

  &.table {
    color: #819dcb;
  }

  &:hover {
    // color: #7d73ff;
    filter: brightness(1.2);
    text-shadow: 0px 0px 4px #7d73ff80, 1px 1px 3px #000000a1;
    // filter: unset;
  }
}

.hd-label {
  white-space: normal;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #9d99a5;
  font-family: 'Roboto', sans-serif;


  &.call-to-action {
    font-size: 28px;
    // color: #ed4800;
    color: #d95a23;
    cursor: pointer;
  }
}

.hd-page-error {
  border: 1px solid #9d99a536;
  border-radius: 16px;
  display: flex;
  padding: 16px;
  justify-content: center;
  height: 100px;
  background: linear-gradient(180deg, #9181b11f 0%, #9e96be14 86%);
}

.subtle-link {
  color: #727272;
  cursor: pointer;
  transition: 200ms linear filter;
  &:hover {
    filter: brightness(1.3);
  }
}

svg {
  // border: 1px solid red;
}

.hd-input {
  color: #e0e0e0;
  background: #19191cc2;
  border: none;
  height: 38px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  font-size: 0.9rem;
  box-shadow: 0px 0px 16px 0px inset #0000002e;

  &.large {
    height: 42px;
    font-size: 0.9rem;
  }

  &.no-margin {
    margin: 0px;
  }

  &.align-right {
    text-align: right;
  }

  &.left-margin {
    margin: 0px;
    margin-left: 8px;
  }
}

select.hd-input {
  border: none;
  border-radius: 8px;
  appearance: none;
  outline: none;
  text-align: center;
  background: transparent;

  option {
    border-radius: 8px;
    padding: 4px;
    background: #27242b;
    appearance: none;
    border: 1px solid #27242b;
    outline: none;
    text-align: right;
    display: flex;
    outline-color: #27242b;
    align-items: center;
  }
}

.hd-section {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px inset #0000004a;
}

.full-height {
  height: 100%;
}

.align-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.align-right {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  text-align: right;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nowrap {
  white-space: nowrap;
}

.flex-span {
  display: flex;
  padding: 0px 2px;
  white-space: nowrap;
}

.hd-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 12px;
  // padding: 10px 24px 10px 12px; // for if we have scrollbar
  font-weight: 600;
  // background: linear-gradient(359deg, #2f2a2a 0%, #232027 100%);
  // background: #3c3641;
  // background: #302c33;
  background: #322e38;
  box-shadow: 0px 0px 16px 0px #00000044;
  text-shadow: 0px 0px 8px #00000088;
  color: $headerText;

  &.has-scroll {
    padding: 10px 24px 10px 12px; // for if we have scrollbar
  }

  &.popup-header {
    font-weight: 600;
    // color: #ffc6ae;
    color: #ebdcc8;
  }

  .title-item {
    text-transform: uppercase;
    line-height: 0.9;
    font-size: 14px;
  }
}

.screen-xs {
  .hd-title.has-scroll {
    padding: 10px 14px 10px 12px; // for if we have scrollbar
  }
}


.col-header {
  padding: 0px 0px 0px 0px;
  // border: 1px solid #ffff0080; // for debugging column widths

  :not(:last-of-type) {
    padding: 0px 8px 0px 0px;
  }

  &.pad-right {
    margin-right: 8px;
  }

  .header-label {
    &.force-show {
      text-overflow: unset;
      overflow: unset;
      white-space: unset;
    }
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: inherit;
    white-space: nowrap;
    // color: #7a7a7a;
    color: #999191;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
  }
}

.col {
  // border: 1px solid #ff000080; // for debugging column widths
  padding: 0px 0px 0px 0px;
  overflow: hidden;
  position: relative;
  &.pad-right {
    margin-right: 8px;
  }

  :not(:last-of-type) {
    padding: 4px;
    // padding: 0px 8px 0px 0px;
  }
}

.grow {
  flex: 1 1 auto;
  flex-shrink: 1;
  padding-left: 8px;
}

.col-flag {
  width: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.col-delta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(16px + 24px + 16px);
}

.col-w-5 {
  width: 5%;
}

.col-w-10 {
  width: 10%;
}

.col-w-15 {
  width: 15%;
}

.col-w-20 {
  width: 20%;
}

.col-w-25 {
  width: 25%;
}

.col-w-30 {
  width: 30%;
}

.col-w-35 {
  width: 35%;
}

.col-w-45 {
  width: 45%;
}

.col-w-40 {
  width: 40%;
}

.col-w-50 {
  width: 50%;
}

.col-w-60 {
  width: 60%;
}

.col-w-80 {
  width: 80%;
}

.col-w-90 {
  width: 90%;
}

.scenario-plays {
  color: #ffc09b;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

// remove this and change any usage to hd-title
.hd-list-title {
  height: 34px;
  background: linear-gradient(359deg, #2f2a2a 0%, #201e23 100%);
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px #00000044;
  text-shadow: 0px 0px 8px #00000088;
  font-size: 14px;
  padding: 12px;
  justify-content: space-between;
  margin-bottom: 16px;

  .hd-list-title-item-1 {
    flex: 1;
  }

  .hd-list-title-item-2 {
    flex: 2;
  }

  .hd-list-title-item-3 {
    flex: 3;
  }

}

@keyframes breath {
  0% {
    filter: brightness(50%);
  }
  50% {
    opacity: 1;
  }
  100% {
    filter: brightness(120%);
  }
}

.modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2000;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .close-modal {
    position: absolute;
    top: -48px;
    right: -48px;
    width: 32px;
    height: 32px;
    margin: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    transition: 200ms linear transform, 200ms linear filter;

    &.inside {
      top: 0px;
      right: 0px;
    }

    &:hover {
      transform: scale(1.1);
      filter: brightness(1.1);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .modal-backdrop {
    width: 100%;
    height: 100%;
    background: #0000007a;
    pointer-events: auto;
    position: absolute;
    transition: 200ms linear opacity;
  }
}

.modal-close {
  margin-top: 10px;
  margin-right: 20px;
  color: #aaaaaa;
  z-index: 100;
  padding: 8px;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
}

.play-video-modal {
  position: absolute;
  box-shadow: 0px 0px 64px 0px #000000;
  pointer-events: auto;
  max-width: 80%;
  width: auto;
  height: auto;
  display: flex;
  transition: 200ms linear opacity;
}

.fullscreen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}


.rank-icon {
  width: 34px;
  height: 34px;
  margin-left: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}


.screen-xs {
  .hd-list-item {
    font-size: 12px !important;
  }

  .card {
    min-height: 40px;
  }

  .hd-tab {
    font-size: 12px;
  }

  .hd-label.call-to-action {
    font-size: 20px;
    line-height: 24px;
    padding: 8px;
  }
}