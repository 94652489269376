@import '../../assets/styles/helper.scss';

.admin-news {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    // justify-content: center;
    align-items: center;
    // color: black;

    &-grid-container {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto;
        // background-color: #2196F3;
        padding: 10px;

        &-item {
            position: relative;
            background-color: rgba(255, 255, 255, 0.8);
            border: 1px solid rgba(0, 0, 0, 0.8);
            padding: 20px;
            font-size: 30px;
            text-align: center;

            &-close-btn {
                width: 10px;
                height: 10px;
                right: 10px;
                position: absolute;
            }

            &-img {
                width: 100px;
                height: 100px;
            }
        }


    }


    &-form {
        &-upload_btn {
            width: 85px;
            height: 26px;
            background-color: #2B272E;
            color: $textColor;
            border-radius: 10px;
            margin-left: 10px;
        }

        &-input {
            border: none;
            height: 26px;
            width: 400px;
            background-color: #2B272E;
            color: $textColor;
        }

        &-submit {
            width: 120px;
            height: 26px;
            background-color: #ED4800;
            color: $textColor;
            border: none;
            border-radius: 25px;
        }

        &-image-picker {
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }

    &-table {
        padding: 10px;
        max-width: 1200px;
        max-height: 400px;
        overflow: scroll;

        &-body {
            &-col {
                // float: left;
                // width: 100%;
            }
        }
    }

    ::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        border: 1px solid #d5d5d5;
    }
}