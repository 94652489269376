@import "../../assets/styles/helper";

.admin-submenu {
    min-height: 100%;
    background-color: #28242B;
    // background: linear-gradient(276deg, #1e1a22 0%, #27242b 100%);

    &-content {
        margin-top: 30px;
        padding: 32px;

        span {
            margin-top: 15px;
            margin-bottom: 15px;
            padding: 8px;
        }

        &-menu {
            @include defaultFont(normal, 500, 20px, 16px);
            margin-top: 30px;
            user-select: none;

            ul {
                padding-left: 30px;
                user-select: none;
            }

            li {
                margin-top: 16px;
                margin-bottom: 16px;
                user-select: none;
            }

            &-item {
                @include defaultFont(normal, 500, 17px, 16px);
                color: #808191;

                &-child {
                    &-active-text {
                      white-space: nowrap;
                        color: $textColor;
                        // background-color: #ED4800;
                        @include defaultFont(normal, 500, 15px, 20px);
                    }

                    &-inactive-text {
                      white-space: nowrap;
                        color: #808191;
                        @include defaultFont(normal, 500, 15px, 20px);
                    }
                }
            }
        }
    }

}