@import '../../assets/styles/helper.scss';

.global-header {
  // background-color: #1E1A22;
  // background: linear-gradient(0deg, rgba(30,26,34,1) 0%, rgba(39,36,43,1) 100%);
  background-color: #28242B;
  display: flex;
  position: fixed;
  width: 100%;
  height: 76px;
  display: flex;
  z-index: 10;
  // box-shadow: 0px 4px 16px 0px #00000044;
  flex-direction: row;
  align-items: center;

  [class*="CountryItemContainer"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0;
    .name {
      display: none;
    }
    [class*="FlagContainer"] {
      width: 22px;
      height: 16px;
      img {
        width: 22px;
        height: 16px;
      }
    }
  }

  .global-menu {
    display: flex;
    right: 0px;
    // position: absolute;
    align-items: center;
    padding: 0px 8px 0px 16px;
    // border: 1px solid red;
  }

  &-content {
    display: flex;
    align-items: center;
    margin: 0 auto;
    min-height: 76px;
    // border: 1px solid blue;

    a {
      padding: 30px 26px;
      height: 76px;
      @include defaultFont(normal, 500, 14px, 17px);
      color: $disabledText;
      user-select: none;
      transition: 120ms linear color;
      white-space: nowrap;
      display: flex;
      align-items: center;

      &:hover {
        color: $selectedText;
        // text-shadow: 0px 0px 8px $textColor;
      }
    }

    a.active {
      border-bottom: 3px solid $metaOrange;
      color: $selectedText;
    }
  }

  &-logo {
    border: none;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 76px;
    width: 130px;
    margin-left: 24px;
    margin-right: 32px;
    display: flex;
    position: relative;

    img {
      transform: scale(0.25);
    }

    .logo-beta {
      font-family: 'Roboto';
      font-size: 8.5px;
      font-weight: bold;
      background: #ed4800;
      color: #322621;
      box-shadow: 0px 0px 3px 0px #3e3e3e;
      position: absolute;
      border-radius: 2px;
      padding: 1px;
      right: -16px;
      top: 25%;
    }
  }

  a.logo:hover {
    border: none;
  }


  &-user {
    height: 76px;
    // position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // right: 0;
    transition: 200ms linear filter, 200ms linear backdrop-filter;

    &:hover {
      filter: brightness(120%);
      backdrop-filter: brightness(120%);
      .global-header-user-label {
        text-shadow: 0px 0px 4px $textColor;
      }
    }

    &-arrow {
      border: solid #808191;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
    }

    &-down {
      transform: rotate(45deg);
    }

    &-notification {
      width: 20px;
      height: 20px;
      margin-left: 20px;
      margin-right: 20px;
    }

    &-avatar {
      max-width: 32px;
      max-height: 32px;
      border-radius: 50%;

      &.steam {
        border-radius: 0px;
      }
    }

    &-label {
      font-size: 14px;
      padding: 0px 4px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;
    }

    &-username {
      color: $textColor;
      @include defaultFont(normal, 500, 13px, 13px);
      display: flex;
      padding: 8px 16px 8px 16px;
      align-items: center;
      transition: 200ms linear opacity;
    }
  }
}

.dropbtn {
  color: $textColor;
  padding: 10px;
  font-size: 10px;
  border: none;
}

.dropdown-backdrop {
  width: 100vw;
  height: 100dvh;
  transition: opacity linear 200ms, backdrop-filter linear 200ms;
  background: linear-gradient(179deg,#1e1a226b 0%,#27242b7a 100%);
  backdrop-filter: blur(1px);
  box-shadow: 0px 0px 16px 0px inset #00000040;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 90;
  opacity: 0;
  pointer-events: none;
  &.show {
    opacity: 1;
    display: visible;
    pointer-events: auto;
  }
}

.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 4px 4px 8px 8px;
  cursor: pointer;
}

.dropdown-overlay {
  box-shadow: 0px 0px 2px 0px inset #8181816b;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  pointer-events: none;
}

.dropdown-content-hide {
  display: none;
}

.dropdown-content-show {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #28242b;
  padding: 8px 0px;
  color: $textColor;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
  top: 25px;
  right: 5px;
  z-index: 100;
  overflow: hidden;
  @include defaultFont(normal, 500, 14px, 13px);

  a {
    padding: 0px 16px;
    height: 38px;
    background: #28242b;
    white-space: nowrap;
    align-items: center;
    color: #b4b3b5;
    transition: color 120ms linear, background-color 120ms linear, text-shadow 120ms linear, box-shadow 120ms linear, filter 120ms linear;
    display: flex;
    justify-content: flex-end;
  }

  a:hover,
  a:focus {
    cursor: pointer;
    color: #e0e0e0;
    filter: brightness(1.2);
  }
}

.screen-xs {
  .global-header-content a {
    display: none;
  }

  .global-header-user-avatar {
    max-width: 28px;
    max-height: 28px;
  }

  .global-header-logo {
    margin-left: 16px;
  }

  .dropdown {
    padding: 0px;
  }
}

.screen-sm {
  .global-header-content a {
    padding: 30px 16px;
  }
}


.screen-xs {
  .global-menu {
    padding-right: 0px;
  }
  .global-header-user {
    height: 50px;
    &-username {
      padding-right: 0px;
    }
  }

  .global-header-logo {
    height: 50px;
    margin: 0;
  }

  .global-header {
    height: 50px;
  }

  .logo-beta {
    top: 20%;
  }
}