@import "../../assets/styles/helper";

$lineColor: #8b8b8b;
$grayColor: #8B8B8B;

.login {
	margin: 20px;
	display: flex;
	flex-direction: column;
	height: 100%;

	@media screen and (min-width: 768px) {
	}

	@media screen and (max-width: 767px) {
	}

	&-container {
		position: relative;
		flex: auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media screen and (min-width: 768px) {
		}

		@media screen and (max-width: 767px) {
		}
	}

	&-logo {
		@media screen and (max-width: 767px) {
			img {
				height: 40px;
			}
		}
	}

	&-title {
    align-self: flex-start;
    padding-left: 16px;

		@media screen and (min-width: 768px) {
			@include defaultFont(normal, 700, 18px, 21px);
		}

		@media screen and (max-width: 767px) {
			@include defaultFont(normal, 700, 18px, 21px);
		}
	}

	&-form {
		display: flex;
		position: relative;
		max-width: 312px;
    padding: 32px 32px 16px 32px;

		&-label-section{
			display: flex;
			flex-direction: column;
			font-size: 16px;
			margin-right: 19px;
			// width: 20%;
			&-label {
				margin-bottom: 10px;
				text-align: right;
				height: 36px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        b {
          color: silver;
          font-size: 16px;
          font-weight: 300 !important;
        }
			}
		}

		&-input-section {
			display: flex;
			flex-direction: column;
			width: 226px;

			&-input {
				color: $textColor;
				background: #19191cc2;
    		border: none;
				margin-bottom: 10px;
				height: 36px;
        padding: 8px;
        border-radius: 8px;
        box-shadow: 0px 0px 16px 0px inset #0000002e;
			}
		}

		@media screen and (min-width: 768px) {
			width: 445px;
		}

		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}


	&-error {
		margin-top: 4px;

		@media screen and (min-width: 768px) {
			max-width: 40%;
			@include defaultFont(normal, normal, 14px, 32px);
		}

		@media screen and (max-width: 767px) {
			@include defaultFont(normal, normal, 12px, 32px);
		}

		color: #F46666;
	}

	&-error+&-error {
		margin-top: 0;
	}

	&-btn-group{
		display: flex;
		flex-direction: row;
		text-align: center;
    justify-content: space-evenly;
    width: 100%;
    user-select: none;
    padding-top: 16px;

    &-button {
      text-align: center;
      box-shadow: 0px 1px 8px 0px #0b0a0a40;
      border-radius: 12px;
      background: #221F26;

      @media screen and (min-width: 768px) {
        width: 123px;
      }

      @media screen and (max-width: 767px) {
        width: 123px;
      }

      button {
        width: 100%;
        color: #33E28E;
        border-radius: 12px;
        padding: 0;
        @include defaultFont(normal, bold, 16px, 42px);
        font-weight: 300;
        text-shadow: 0px 0px 4px #33E244;
        transition: 200ms linear box-shadow, 200ms linear text-shadow, 200ms linear filter;

        &:hover {
          box-shadow: 0 2px 8px 0 #0b0a0a6b;
          text-shadow: 0px 0px 8px #33E288;
          font-weight: 500 !important;
          filter: brightness(1.2);
        }

        &:disabled {
          background: #221F26;
          color: #33E28E;
          cursor: not-allowed;
        }
      }
    }

		&-option {
      box-shadow: 0 1px 8px 0 #0b0a0a40;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      // border: 1px solid #0b0a0a1f;
      font-size: 11px;
      background: #221F26;
      border-radius: 12px;
      margin-right: 10px;
      transition: 200ms linear box-shadow, 200ms linear text-shadow;

      &:hover {
        box-shadow: 0 2px 8px 0 #0b0a0a6b;
        font-weight: 700;

        a {
          text-shadow: 0px 0px 8px #ff4040;
          font-weight: 500 !important;
        }
      }

      @media screen and (min-width: 768px) {
        width: 123px;
        @include defaultFont(normal, normal, 16px, 30px);
      }

      @media screen and (max-width: 767px) {
        width: 123px;
        @include defaultFont(normal, normal, 14px, 30px);
      }

      span {
        margin-right: 8px;
      }

      a {
        color: #FF4040;
        text-shadow: 0px 0px 4px #ff4040;
        white-space: nowrap;
        font-weight: 300;

        @media screen and (min-width: 768px) {
          font-size: 16px;
          line-height: 30px;
        }

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 30px;
        }

        @media screen and (max-width: 370px) {
          display: block;
          margin-left: 0;
        }
      }
    }
  }

}