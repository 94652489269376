@import "../../assets/styles/helper";

.sidebar {
  min-width: 238px;
  background-color: #28242B;
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  flex: 0;
  transition: 200ms ease-out min-width;
  // background: linear-gradient(276deg, rgba(30, 26, 34, 1) 0%, rgba(39, 36, 43, 1) 100%);

  &-icon-menu {
    width: 16px;
    height: 16px;
  }

  &-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;

    &.collapsed {
      justify-content: center;
    }

    align-items: center;
    user-select: none;
    padding: 16px 32px;
    transition: 200ms linear background;

    span.sidebar-inactive-text {
      transition: 120ms linear color;
    }

    &:hover {
      // filter: brightness(120%);
      // backdrop-filter: brightness(120%);
      background: #34303f91;

      span.sidebar-inactive-text {
        color: $selectedText;
        // text-shadow: 0px 0px 8px $textColor;
      }
    }

    span {
      white-space: wrap;
    }
  }

  &-topitem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    cursor: pointer;
    // transition: 200ms linear backdrop-filter;

    img {
      transition: 200ms linear filter;
      user-select: none;
    }

    &:hover {
      // filter: brightness(120%);
      // backdrop-filter: brightness(120%);
      background: #34303f91;

      img {
        // filter: brightness(1.2) drop-shadow(0px 0px 6px #ffffff88);
      }
    }
  }

  &-menu {
    // margin-top: 26px;

    a {
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        width: 32px;
        height: 32px;
        background-color: #3E3A40;
        flex-shrink: 0;
      }
    }

    a:not(.active) {
      .icon {
        img:nth-child(2) {
          display: none;
        }
        div:nth-child(2) {
          display: none;
        }
      }

      span {
        color: $disabledText;
        @include defaultFont(normal, 500, 14px, 16px);
        margin-left: 16px;
      }
    }

    a.active {
      .icon {
        background-color: $metaOrange;

        img:nth-child(1) {
          display: none;
        }
        div:nth-child(1) {
          display: none;
        }
      }

      span {
        color: $textColor;
        @include defaultFont(normal, 500, 14px, 16px);
        margin-left: 16px;
      }

    }
  }

  &-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    &-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &.collapsed {
        a {
          margin-bottom: 4px;
        }
      }

      img {
        filter: brightness(1.2);
      }

      &-item {
        margin: 0px 2px;
        transition: 120ms linear filter;
        color: #48454a;
        filter: drop-shadow(2px 2px 2px #14141480);
        transition: 120ms linear filter;

        &:hover {
          filter: brightness(1.3);

          img {
            filter: brightness(150%);
          }
        }
      }
    }

    &-copyright {
      text-align: center;
      @include defaultFont(normal, 500, 10px, 16px);
      // color: #39363B;
      color: #48454a;
      margin-top: 10px;
      text-shadow: 0px 0px 2px #1b1717cf;
    }
  }

}