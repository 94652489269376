@import "../../assets/styles/helper";

.signup {
	margin: 16px;

	display: flex;
	flex-direction: column;
	height: 100%;

	@media screen and (min-width: 768px) {}

	@media screen and (max-width: 767px) {}

	&-container {
		position: relative;
		flex: auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media screen and (min-width: 768px) {}

		@media screen and (max-width: 767px) {}
	}

	&-logo {
		@media screen and (max-width: 767px) {
			img {
				height: 40px;
			}
		}
	}

	&-title {
    width: 100%;
    line-height: 30px !important;
    padding-left: 8px;
    margin-bottom: 16px;

		@media screen and (min-width: 768px) {
			@include defaultFont(normal, 700, 18px, 21px);
		}

		@media screen and (max-width: 767px) {
			@include defaultFont(normal, 700, 18px, 21px);
		}
	}

  &-header {
    text-align: center;
    margin-bottom: 16px;
  }

  &-steam-login {
    padding: 8px;
    transform: scale(1.2);
  }

  &-info {
    font-size: 14px;
    padding: 8px;
    border: 1px solid #ed4800ad;
    border-radius: 8px;
    color: #ed4800d4;
    background: #ed480014;
    // margin: 0px 2px 8px 2px;
    margin: 0px 0px 8px 0px;
    line-height: 18px;
  }

	&-form {
		display: flex;
    flex-direction: column;
		position: relative;
    width: 80%;
    padding-top: 16px;

		&-label-section {
			display: flex;
			flex-direction: row;
			font-size: 16px;
      justify-content: space-between;
      // align-content: center;
      align-items: center;


      &-wrapper {
        // border: 1px solid magenta;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        flex: 1;
      }

			&-label {
				text-align: right;
				height: 36px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
        white-space: nowrap;
        color: silver;
        flex: 1;
        padding: 8px 16px 8px 0px;
			}
		}

		&-input-section {
			display: flex;
			flex-direction: column;
			width: 226px;

			&-input {
				color: $textColor;
				background: #221F26;
				border: none;
				height: 36px;
        width:100%;
			}

			&-birthdate {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 36px;
        margin: 4px 0px 8px 0px;
        width: 100%;

				&-select {
					border: none;
					margin-right: 2px;
					border-radius: 8px;
					background-color: #221F26;
					color: $textColor;
					height: 36px;
          padding: 8px;
          margin-left: 4px;
				}
			}
		}

    &-checkbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px;
      user-select: none;
      font-size: 12px;
      width: 100%;
      line-height: 18px;
      margin-bottom: 4px;
      border-radius: 8px;

      &:hover {
        filter: brightness(120%);
      }

    }

		@media screen and (min-width: 768px) {}

		@media screen and (max-width: 767px) {}
	}

	&-btn-group {
		display: flex;
		flex-direction: row;
		text-align: center;
    margin-top: 16px;

		&-button {
			text-align: center;

			@media screen and (min-width: 768px) {
				width: 123px;
			}

			@media screen and (max-width: 767px) {
				width: 123px;
			}

			button {
				width: 100%;
				background: #221F26;
				color: #FF4040;
				border-radius: 12px;
				padding: 0;
				@include defaultFont(normal, bold, 16px, 32px);

				&:disabled {
					background: #221F26;
					color: #33E28E;
					cursor: not-allowed;
				}
			}
		}

		&-option {
      display: flex;
      justify-content: center;
      align-items: center;
			font-size: 11px;
			background: #221F26;
			border-radius: 12px;
			margin-left: 10px;
			color: #33E28E;

			@media screen and (min-width: 768px) {
				// margin-top: 80px;
				width: 123px;
				@include defaultFont(normal, normal, 16px, 30px);
			}

			@media screen and (max-width: 767px) {
				width: 123px;
				// margin-top: 16px;
				@include defaultFont(normal, normal, 14px, 30px);
			}

			span {
				margin-right: 8px;
			}

			a {
				font-weight: bold;

				color: #33E28E;
				white-space: nowrap;
				cursor: pointer;

				@media screen and (min-width: 768px) {
					font-size: 16px;
					line-height: 30px;
				}

				@media screen and (max-width: 767px) {
					font-size: 14px;
					line-height: 30px;
				}

				@media screen and (max-width: 370px) {
					display: block;
					margin-left: 0;
				}
			}
		}
	}
}

.checkbox-round {
	width: 1.2em;
	height: 1.2em;
	background-color: #00000044;
  box-shadow: 0px 0px 4px 0px inset #000000a1;
	border-radius: 50%;
	vertical-align: middle;
	-webkit-appearance: none;
	outline: none;
	cursor: pointer;
  transition: 120ms linear background, 120ms linear box-shadow;

  &:checked {
    // box-shadow: 0px 0px 4px 0px inset #000000a1, 0px 0px 8px 0px #ed4800;
    box-shadow: 0px 0px 2px 0px inset #7e7e7e91;
    background-color: #ED4800;
  }

}

.chkbox {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	margin-top: 32px;
  width: 80%;
	@include defaultFont(normal, normal, 10px, 12px);
}