@import '../../assets/styles/helper.scss';

.admin-day-scenario {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
    color: $textColor;

    &-form {
      width: 600px;

        &-input {
            border: none;
            height: 26px;
            width: 400px;
            background-color: #2B272E;
            color: $textColor;
        }

        &-upload_btn {
            width: 85px;
            height: 26px;
            background-color: #2B272E;
            color: $textColor;
            border-radius: 10px;
        }

        &-submit {
            width: 120px;
            height: 26px;
            background-color: #ED4800;
            color: $textColor;
            border: none;
            border-radius: 25px;
        }

        &-sumbit_disable {
            width: 120px;
            height: 26px;
            background-color: #49484b;
            color: $textColor;
            border: none;
            border-radius: 25px;
            cursor: not-allowed;
        }

        &-image-picker {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-desc {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &-textarea {
                width: 100%;
                resize: none;
                background-color: #2B272E;
                color: $textColor;
                border: none;
                padding: 5px;
            }
        }
    }

    &-table {
        padding: 10px;
        max-width: 1200px;
        max-height: 400px;
        overflow: scroll;
    }

    ::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        border: 1px solid #d5d5d5;
    }
}