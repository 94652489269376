.admin-scene {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    transition: opacity 200ms linear;

    &-container {
        display: flex;
        width: 100%;
        justify-content: center;
        background-color: #221F26;
        background: linear-gradient(90deg, #1e1a22 0%, #27242b 100%);
        box-shadow: 0px 0px 16px 0px inset #00000024;
    }
}