@import "../../assets/styles/helper";

// @keyframes glow {
//   0% {
//       filter: drop-shadow(0px 0px 5px rgba(243, 96, 45, 0.3));
//   }
//   50% {
//       filter: drop-shadow(0px 0px 5px rgba(243, 96, 45, 1.0));
//   }
//   100% {
//       filter: drop-shadow(0px 0px 5px rgba(243, 96, 45, 0.3));
//   }
// }

@keyframes shine {
  0% {
    transform: translateX(-75%);
  }
  100% {
    transform: translateX(25%);
  }
}


.animate-background {
  animation: shine 3s ease-out infinite;
}

.animate-glow {
  animation: glow 3s ease infinite;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  z-index: -1;
}

.fade-exit-active {
  opacity: 0;
}

.route-transition-wrapper {
  display: flex;
  flex: 1;
  position: relative;
}

.content-wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  transition: opacity 200ms linear;
}

.kovaaks {
	display: flex;
	flex-direction: column;
  align-items: center;

  overflow: hidden;
  position: relative;
  height: 100%;

	&-container {
		display: flex;
		width: 100%;
    height: 100%;
    max-width: 2400px;
    position: relative;

		&-block {
			display: flex;
			flex-direction: column;
			width: 100%;
      height: 100%;
			padding: 16px 48px 32px 48px;
      // background: linear-gradient(90deg, #1e1a22 0%, #27242b 100%);
      box-shadow: 0px 0px 16px 0px inset #00000024;
      max-width: 2400px;
      overflow-y: auto;

      &.admin {
        background: #1e1a22;
      }

      &.fixed {
        overflow-y: hidden;
      }

			&-top {
				display: flex;
				align-items: center;
				justify-content: space-around;
				flex-direction: row;
        margin-top: 32px;
			}

			&-my-feed {
				margin-top: 16px;
			}

			&-bottom {
				display: flex;
				margin-top: 50px;
			}
		}
	}
}

.screen-xs {
  .kovaaks-container-block {
    padding: 8px 16px 16px 16px;
    overflow-x: hidden;
    margin-top: 0px;
  }

  .kovaaks-container-block.noscroll {
    overflow-y: hidden;
  }

  .kovaaks-container-block-top {
    margin-top: 0px;
  }
}