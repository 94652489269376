/*==============================================
  App styles
==============================================*/

// Style configuration files
@import 'base.scss';

// Helper classes
@import 'helper.scss';

@import 'atomic.scss';

.footer {
  position: absolute;
  height: $footerHeight;
  bottom: 0px;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px;
  justify-content: flex-end;
  font-size: 13px;
  z-index: 1000;

  .item {
    margin-right: 16px;
  }
}