@import "../../assets/styles/helper";

.kovaaks-league {
    display: flex;
	flex-direction: column;

    &-container {
        display: flex;
        height: 100dvh;

        &-content {
            display: flex;
			flex-direction: column;
			width: calc(100% - 238px);
			padding: 65px 44px;
			background-color: #221F26;
        }
    }
}