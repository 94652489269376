@mixin defaultFont($style, $weight, $size, $lineHeight:normal) {
	font-family: 'Roboto', sans-serif;
	font-style: $style;
	font-weight: $weight;
	font-size: $size;
	line-height: $lineHeight;
}

@mixin color($color, $opacity:1) {
	-webkit-text-fill-color: $color;
	-webkit-opacity: $opacity;
	color: $color;
}

@mixin textShrink($maxLine:1) {
	text-overflow: ellipsis;
	// white-space: nowrap;
	overflow: hidden;
	overflow-wrap: anywhere;

	display: -webkit-box;
	-webkit-line-clamp: $maxLine;
	-webkit-box-orient: vertical;
}

@mixin verticalScroll($color:#26282A) {
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: $color;
	}
}

@mixin horizontalScroll($color:#26282A) {
	overflow-x: auto;
	overflow-y: hidden;

	&::-webkit-scrollbar {
		height: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
    padding: 0px 2px;
		background-color: $color;
	}
}

@mixin selectionColor($fgColor: white, $bgColor: #0561d1) {
	&::selection {
		color: $fgColor !important;
		background-color: $bgColor !important;
	}

	&::-moz-selection {
		color: $fgColor !important;
		background-color: $bgColor !important;
	}
}

$appGreenColor: #61CEF7;
$errorColor: #F46666;
$textColor: #e0e0e0;
$disabledText: #808191;
$headerText: #7a7a7a;
$selectedText: #e0e0e0;
$metaOrange: #ed4800;
$black: #111111;
$linkText: #ff8b5d;
$tableText: #ffc09b;
$buttonText: #111111;
$linkShadow: 0px 0px 6px #ff5e00a1, 1px 1px 3px #000000a1;
$footerHeight: 40px;

