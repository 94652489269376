/*==============================================
  Base Styles
==============================================*/
@import './helper.scss';

* {
	box-sizing: border-box;
}

body {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;
	color: $textColor;

	margin: 0;
	width: 100vw;
  height: 100dvh;
	overflow-x: hidden;
	background-color: #221f26;
  user-select: none;

	@include verticalScroll();
}

body.keyboard {
	.h-menu {
		background: red;
		bottom: 270px;
	}
}

h1, h2 {
  font-size: 1.3rem;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

a {
	text-decoration: none;
	outline: none;

	&:active,
	&:focus,
	&:hover,
	&:visited {
		text-decoration: none;
		outline: none;
	}
}

button {
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0;
	background: initial;
  user-select: none;

	&:focus,
	&:hover {
		outline: none;
	}
}

// img {
// 	cursor: pointer;
// }

#root {
	position: relative;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

*:focus {
	outline: none;
}

*::selection {
	color: $selectedText;
	background-color: #0561d1;
}

*::-moz-selection {
	color: $selectedText;
	background-color: #0561d1;
}


.d-none {
	display: none !important;
}

.d-hidden {
	opacity: 0;
}

.d-block {
	display: block !important;
}

.pr-24 {
	padding-right: 24px;
}

.ml-16 {
	margin-left: 16px;
}

.mr-16 {
	margin-right: 16px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-24 {
	margin-top: 24px;
}

.mt-37 {
	margin-top: 37px;
}

.mt-48 {
	margin-top: 48px;
}

.b-44 {
	bottom: 44px;
}

.w-445 {
	@media screen and (min-width: 768px) {
		width: 445px;
	}

	@media screen and (max-width: 767px) {
		width: 100%;
	}
}

.user-selectable {
	user-select: text;
}

.no-scroll {
	height: 100dvh;
	overflow-y: hidden;
}

.transparent {
	opacity: 0 !important;
	pointer-events: none;

	* {
		opacity: 0 !important;
		cursor: default !important;
	}
}

.page-container {
	position: relative;
	background: #ffffff no-repeat fixed;
	max-width: 100%;
	min-height: 100dvh;
	overflow-x: hidden;

	@media screen and (min-width: 768px) {}

	@media screen and (max-width: 767px) {}
}

.mask-parent {
	position: relative !important;
}

.gray-color {
	color: #8B8B8B !important;
}

.top-bar {
	position: relative;
	height: 8px;
	background: #61CEF7;
	transition: width 0.5s;
}

.desktop {
	@media screen and (min-width: 992px) {}

	@media screen and (max-width: 991px) {
		display: none !important;
	}
}

.mobile {
	@media screen and (min-width: 992px) {
		display: none !important;
	}

	@media screen and (max-width: 991px) {}
}

.no-ss-mobile {
	@media screen and (min-width: 375px) {}

	@media screen and (max-width: 374px) {
		display: none !important;
	}
}

.ss-mobile {
	@media screen and (min-width: 375px) {
		display: none !important;
	}

	@media screen and (max-width: 374px) {}
}

.text-orange {
  display: flex;
	color: $linkText !important;
  transition: 200ms linear text-shadow, 200ms linear color, 200ms linear filter;
  white-space: nowrap;

  .flow {
    display: inline-block !important;
  }

  &.link {
    cursor: pointer;
  }

  &:hover {
    color: $metaOrange !important;
    text-shadow: 0px 0px 6px #ff5e005c, 1px 1px 3px #000000a1;
    filter: brightness(150%);
  }
}

* {
  // scrollbar-color: #bd3900 #222222;
  // scrollbar-width: auto;
  font-family: 'Roboto', sans-serif;
}


/* Track */
::-webkit-scrollbar-track {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #363636;
}

.hd-list {
  &::-webkit-scrollbar-track {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background: #363636;
  }
}


/* Handle */

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $metaOrange;
}

.screen-xs {
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    background: #bd3900;
    border-radius: 10px;
    z-index: 100;
  }
}

.screen-sm, .screen-md, .screen-lg {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
		height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #bd3900;
    border-radius: 10px;
    width: 8px;
    overflow: hidden;
    z-index: 100;
  }
}