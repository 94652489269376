@import '../../assets/styles/helper.scss';

.login-modal {
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    backdrop-filter: brightness(0.9) blur(4px);

  &-content {
    margin: auto;
    border-radius: 16px;
    width: 482px;
    position: relative;
    background: #27242b;
    box-shadow: 0px 8px 32px 0px #00000085, 0px 0px 8px 0px inset #0b0b0b14;

    &-close {
      color: #aaaaaa;
      z-index: 100;
      padding: 8px;
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 28px;
      font-weight: bold;
    }

    &-close:hover,
    &-close:focus {
      color: $selectedText;
      text-decoration: none;
      cursor: pointer;
    }
  }
}


.screen-xs {
  .login-modal-content {
    max-width: 95%;
  }
}